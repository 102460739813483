<template>
  <div id="app" :class="{ unfaded: unfaded }">
    <Particles id="tsparticles" :options="options" />
    <TopBar />
    <router-view/>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue';

export default {
  name: 'App',

  components: {
    TopBar
  },

  data() {
    return {
      unfaded: false
    }
  },

  computed: {
    options() {
      return {
        //background: { color: { value: '#0d47a1' } },
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'canvas',
          events: {
            onClick: {
              enable: true,
              mode: 'push'
            },
            onHover: {
              enable: true,
              mode: 'attract'//'repulse'
            },
            resize: true
          },
          modes: {
            /*bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40
            },*/
            push: { quantity: 4 },
            /*repulse: {
              distance: 200,
              duration: 0.4
            }*/
          }
        },
        particles: {
          color: { value: '#55aaff' },
          links: {
            color: '#ffaaff',
            distance: 150,
            enable: true,
            opacity: 0.2,
            width: 1
          },
          collisions: { enable: true },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 3,
            straight: false
          },
          number: {
            density: {
              enable: true,
              value_area: 800
            },
            value: 80
          },
          opacity: { value: 0.2 },
          shape: { type: 'circle' },
          size: {
            //random: true,
            value: 1
          }
        },
        detectRetina: true
      }
    }
  },

  mounted() {
    setTimeout(() => { this.unfaded = true;}, 100);
  },

  methods: {
  }
}

</script>

<style lang="less">
@import 'assets/main.less';
@import 'assets/vars.less';

body {
  margin: 0;
  padding: 0;
  background-color: @colorMainBg;
  width: 100%;
  height: 100%;
  font-size: 1.2vw;

  @media @phone { font-size: 4.5vw; }
  @media @tablet { font-size: 2vw; }
}

#app {
  font-family: 'Roboto', 'IBM Plex Sans', Avenir, Helvetica, Arial, sans-serif; //'Roboto'
  font-weight: 300;
  line-height: 1.27;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  //text-align: center;
  //color: #2c3e50;
  //position: relative;
  //width: 100%;
  opacity: 0;
  .pv-transition(opacity 1s);

  &.unfaded { opacity: 1; }

  #tsparticles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  //transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
  //opacity: 0;
}

</style>
