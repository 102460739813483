import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollactive from 'vue-scrollactive';
import Particles from "particles.vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faDesktop, faDownload, faNotesMedical, faServer, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue2TouchEvents from 'vue2-touch-events';

library.add(faDesktop, faNotesMedical, faChevronLeft, faChevronRight, faServer, faTimes, faDownload);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(VueScrollactive);
Vue.use(Particles);
Vue.use(Vue2TouchEvents);

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) { window.removeEventListener('scroll', f) }
    }
    window.addEventListener('scroll', f)
  },
  /*unbind: function(el, binding) {
    console.log('unbind:', el, binding);
  }*/
});

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
    show() {
      console.log('show:', window);
    },
    hide() {
      console.log('hide:', window);
    }
  }
}).$mount('#app')
