<template>
  <div class="lang-control">
    <span v-for="(l, index) in langs" :key="index" @click="onClick($event, l)" class="lang-item select">
      {{ l.name }}
    </span>
    <span class="lang-item flex center">{{ currentLang.name }}</span>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from 'vuex';
import { service } from '../store/service';

export default {
  name: 'LangControl',

  computed: {
    ...mapGetters(['langs', 'currentLang'])
  },

  created() {
    //console.log('lang created | path:', this.$route.path.toUpperCase(), service.lang.lang[service.lang.ru].name, this.$route.path.toUpperCase().indexOf(service.lang.lang[service.lang.ru].name));

    if (this.$route.path.toLowerCase().indexOf(service.lang.lang[service.lang.ru].code) > -1) {
      this.setLang(service.lang.ru);
    }
  },

  methods: {
    ...mapMutations(['setLang']),

    onClick(e, l) {
      //console.log(e, l);
      //this.setLang(l.index);
      this.$router.replace(`/${l.code}/`);
      this.setLang(l.index);
      //console.log(l.index, service.lang[l.index], this.$router);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorLangBg: #777777;
@colorLang: #222222;

.lang-control {
  position: absolute;
  background-color: @colorLangBg;
  color: @colorLang;
  border-bottom-left-radius: @langControlRadius;
  border-bottom-right-radius: @langControlRadius;
  z-index: 1000;
  right: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  font-weight: 500;
  margin-top: -@langElementHeight;
  margin-right: 0.5em;
  .pv-transition-all(0.3s);

  &:hover { margin-top: 0; }

  .lang-item {
    height: @langElementHeight;
    // width: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 @langElementHPadding;

    &.select {
      cursor: pointer;

      &:hover {
        background-color: @colorLinkHover;
      }
    }
  }

  @media @phone { font-size: 1em; }
}

</style>