
const dict = {
  'menu_news': ['News', 'Новости'],
  'menu_approach': ['Approach', 'Подход'],
  'menu_newstep': ['Steps', 'Этапы'],
  'menu_partners': ['Partners', 'Партнёры'],
  'menu_about': ['Information', 'Информация'],
  'menu_contacts': ['Contacts', 'Контакты'],
  'main_h1': ['PathVision.ai – AI-powered digital pathomorphology platform', 'PathVision.ai - платформа цифровой патоморфологии с искусственным интеллектом'],
  'main_text': [
    `At PathVision.ai, we are determined to create a <i class="mark">comprehensive AI-based tool</i>,
    which will dramatically <i class="mark">improve the accuracy</i> of decision-making during the diagnostics in digital pathology.
    By combining highly accurate <i class="mark">neural networks</i> with an advanced <i class="mark">diagnostic software</i>,
    we create a new step in diagnostic process, which will improve the accuracy and <i class="mark">save a lot of time</i> for a specialist.`,
    `В PathVision.ai мы намерены создать комплексный <i class="mark">инструмент на основе искусственного интеллекта</i>,
    который значительно <i class="mark">повысит точность</i> принятия решений во время проведения патоморфологической диагностики.
    Комбинируя высокоточные <i class="mark">нейронные сети</i> с передовым <i class="mark">диагностическим программным обеспечением</i>,
    мы создаем новый этап диагностического процесса, который повысит точность и <i class="mark">сэкономит много времени</i> для специалиста.`
  ],

  'approach_h1': ['Unique approach', 'Уникальный подход'],
  'approach_text': [
    `The new step in the diagnostic process will <i class="mark">provide</i> the doctor with a <i class="mark">preliminary analysis</i> of the digital slide.
    It will include the automatic <i class="mark">labeling</i> of the tissue and a text description of the potential <i class="mark">diagnosis</i>,
    which is set by the <i class="mark">neural networks</i> within the solution.`,
    `На новом этапе диагностического процесса врач сможет <i class="mark">получить предварительный анализ</i> цифрового слайда.
    Он будет включать в себя автоматическую <i class="mark">маркировку</i> ткани и текстовое описание потенциального <i class="mark">диагноза</i>,
    который устанавливается комплексом <i class="mark">нейронных сетей</i> внутри решения.`
  ],

  'newstep_h1': ['New step in diagnostics', 'Новый этап диагностики'],
  'newstep_text': [
    `We are introducing a new step into the <i class="mark">diagnostic process</i>,
    which will <i class="mark">increase the accuracy</i> of the diagnosis for patients and will <i class="mark">improve the efficiency</i> of the doctors,
    <i class="mark">saving</i> a lot of <i class="mark">resources</i> for the medical institution.`,
    `Мы вводим новый этап в <i class="mark">диагностический процесс</i>,
    который <i class="mark">повысит точность</i> диагноза для пациентов и <i class="mark">повысит эффективность</i> работы врачей,
    <i class="mark">сэкономив</i> массу <i class="mark">ресурсов</i> для медицинского учреждения.`
  ],
  'steps_text_1': [
    `With the introduction of whole slide imaging (WSI), pathologists are now able to navigate through a virtual slide on their monitor screens.
    This has made <i class="mark">new opportunities</i> possible, including digital <i class="mark">collaboration/telepathology</i>,
    integration with <i class="mark">electronic workflows</i> and health records,
    and diagnostic support based on computational tools like <i class="mark">artificial intelligence</i>.`,
    `С введением технологии визуализации всего слайда (WSI) патологоанатомы теперь могут перемещаться по виртуальному слайду на экранах своих мониторов.
    Это привело к <i class="mark">новым возможностям</i>, включая <i class="mark">цифровое сотрудничество/телепатологию</i>,
    интеграцию с <i class="mark">электронными рабочими процессами</i> и записями о состоянии здоровья,
    а также диагностическую поддержку на основе вычислительных инструментов, таких как <i class="mark">искусственный интеллект</i>.`
  ],
  'steps_text_2': [
    `The whole slide image is sent to the PathVision.ai infrastructure, where it will be <i class="mark">processed by neural networks</i> in a minute.
    Afterward, it is transferred back to the specialist <i class="mark">fully classified</i> tissue with a text description,
    describing the opinion of the neural network on the possible <i class="mark">diagnosis</i>.`,
    `Полное изображение слайда отправляется в инфраструктуру PathVision.ai, где оно будет <i class="mark">обработано нейронными сетями</i> в течение минуты.
    Затем он передается обратно специалисту с <i class="mark">полностью классифицированной</i> тканью и текстовым описанием,
    описывающим мнение нейронной сети о возможном <i class="mark">диагнозе</i>.`
  ],
  'steps_text_3': [
    `The <i class="mark">final diagnosis</i> and the <i class="mark">treatment plan</i> are set <i class="mark">by the doctor</i>.
    The specialist, who still posesses the full responsibility on the outcome,
    must review the case once again and use <i class="mark">the opinion of PathVision.ai</i> in order to set the diagnosis and set up the treatment plan.`,
    `<i class="mark">Окончательный диагноз</i> и <i class="mark">план лечения</i> устанавливает врач.
    Специалист, который по-прежнему несет полную ответственность за исход лечения,
    должен еще раз рассмотреть случай и использовать <i class="mark">мнение PathVision.ai</i>, чтобы установить диагноз и составить план лечения.`
  ],
  'steps_title_1': ['Whole slide imaging', 'Визуализация всего слайда'],
  'steps_title_2': ['PathVision.ai slide processing', 'Обработка слайда PathVision.ai'],
  'steps_title_3': ['Final diagnosis', 'Финальный диагноз'],

  'partners_h1': ['Our partners', 'Наши партнёры'],
  'partners_ckb_text': [
    'Central Clinical Hospital of the Presidential Administration of the Russian Federation',
    'ФГБУ «Центральная клиническая больница с поликлиникой» Управления делами Президента Российской Федерации'
  ],
  'partners_mnioi_text': [
    'P. Hertsen Moscow Oncology Research Institute - branch of the National Medical Research Radiological Centre of the Ministry of Health of the Russian Federation',
    'Московский научно-исследовательский онкологический институт имени П. А. Герцена Министерства здравоохранения Российской Федерации'
  ],
  'partners_ma_text': ['The Moscow Accelerator', 'Московский акселератор'],
  'partners_sk_text': ['Skolkovo Innovation Center', 'Инновационный центр Сколково'],
  'partners_medtech_text': ['Moscow center for healthcare innovations', 'Московский центр инновационных технологий в здравоохранении'],
  'partners_unim_text': ['UNIM', 'ЮНИМ'],
  'partners_gemotest_text': ['Medical laboratory Gemotest', 'Медицинская лаборатория Гемотест'],
  'partners_mknc_text': ['Moscow Clinical Scientific Center named after A.S. Loginov', 'Московский Клинический Научный Центр им. А.С. Логинова'],
  'partners_gkb40_text': ['Moscow multidisciplinary clinical center "Kommunarka"', 'Московский многопрофильный клинический центр "Коммунарка"'],
  'partners_nmhc_text': ['National Medical and Surgical Center named after N.I. Pirogov', 'Национальный медико-хирургический Центр им. Н.И. Пирогова'],
  'partners_nmic_text': ['National Medical Research Center of Surgery named after A.V. Vishnevsky', 'Национальный медицинский исследовательский центр хирургии им. А.В. Вишневского'],
  'partners_yarod_text': ['Yakut Republican Oncological Dispensary', 'Якутский Республиканский Онкологический Диспансер'],
  'partners_sarood_text': ['Saratov Regional Clinical Oncological Dispensary', 'Саратовский областной клинический онкологический диспансер'],
  'partners_hadassa_text': ['Moscow branch of Hadassah University Hospital (Israel)', 'Московский филиал университетской больницы Hadassah (Израиль)'],
  'partners_ryzhikh_text': ['National Medical Research Center of Coloproctology named after A.N. Ryzhikh', 'Национальный медицинский исследовательский центр колопроктологии имени А.Н. Рыжих'],

  'about_h1_1': ['Information', 'Информация'],
  'about_h1_2': ['Pricing', 'Ценообразование'],
  'about_text_1_0': ['General information', 'Общая информация'],
  'about_text_1_1': [
    'Introducing PathVision.ai – a software package, medical device type 274840.',
    'Представляем вашему вниманию PathVision.ai – программный комплекс, медицинское изделие вида 274840.'
  ],
  'about_text_1_2': [
    '<i class="mark">Medical device registration number:</i> <i class="mark blue">РЗН 2024/23075</i>',
    '<i class="mark">Регистрационный номер медицинского изделия:</i> <i class="mark blue">РЗН 2024/23075</i>'
  ],
  'about_text_1_3': [
    '<i class="mark">Name of the medical device type:</i> <i class="block">Software for interpreting histology/Cytology/Microbiology IVD images</i>',
    '<i class="mark">Наименование вида медицинского изделия:</i> <i class="block">Программное обеспечение для интерпретации изображений гистологии/цитологии/микробиологии ИВД.</i>'
  ],
  'about_text_1_4': [
    '<i class="mark">Classification features of the medical device type:</i> <i class="block">Interpretive software with specific image analysis algorithms [for example, based on artificial intelligence], designed for use in the analysis of clinical samples (for example, biopsy samples, blood smears, urine) and/or microbial cultures for the quantitative/qualitative determination of cellular markers (for example, hormone receptors), changes in tissue architecture and/or morphological/physiological characteristics of cells (e.g., nucleus size, cytoplasmic granularity, cell division rates) and/or infectious microorganisms associated with diseases (e.g. breast cancer, malaria, urinary tract infections). The results are also interpreted by the doctor when managing the patient.</i>',
    '<i class="mark">Классификационные признаки вида медицинского изделия:</i> <i class="block">Интерпретирующее программное обеспечение со специфическими алгоритмами анализа изображений (например, основанными на искусственном интеллекте), предназначенное для использования при анализе клинических образцов (например, образцов биопсии, мазков крови, мочи) и/или микробных культур для количественного/качественного определения клеточных маркеров (например, рецепторов гормонов), изменений в тканевой архитектуре и/или морфологических/физиологических характеристик клеток (например, размера ядра, цитоплазматической гранулярности, скорости деления клеток) и/или инфекционных микроорганизмов, ассоциируемых с заболеваниями (например, раком молочной железы, малярией, инфекциями мочевыводящих путей). Результаты также интерпретируются врачом при ведении пациента.</i>'
  ],
  'about_text_1_5': [
    '<i class="mark">Potential risk class of a medical device usage:</i> <i class="block">3 (high-risk medical devices)</i>',
    '<i class="mark">Класс потенциального риска применения медицинского изделия:</i> <i class="block">3 (медицинские изделия с высокой степенью риска)</i>'
  ],
  'about_text_1_6': [
    '<i class="mark">OKPD2 code:</i> <i class="block">32.50.50.190</i>',
    '<i class="mark">Код ОКПД2:</i> <i class="block">32.50.50.190</i>'
  ],
  'about_text_1_7': [
    '<i class="mark">PathVision.ai is included in the software register of the Russian Federation:</i> <i class="block">registry entry No.22989 dated 06/28/2024</i>',
    '<i class="mark">PathVision.ai входит в реестр отечественного ПО:</i> <i class="block">реестровая запись №22989 от 28.06.2024</i>'
  ],
  'about_text_1_8': [
    '<i class="mark">PathVision.ai contains patented algorithms:</i> <i class="block">patent 2757256</i>',
    '<i class="mark">PathVision.ai содержит запатентованные алгоритмы:</i> <i class="block">патент 2757256</i>'
  ],
  'about_text_1_9': [
    '<i class="mark">Path Vision.ai does not require any third-party licensed software,</i> <i class="block">and is fully relies on an open-source stack</i>',
    '<i class="mark">PathVision.ai для работы не требует какого-либо закрытого/платного ПО,</i> <i class="block">построен на open-source стеке технологий и защищен от санкционных рисков.</i>'
  ],
  'about_text_1_10': [
    'Developer of the Path Vision.ai - OOO "Tsifrovoy Oncomorpholog", is a <i class="mark">Skolkovo</i> resident.',
    'Разработчик PathVision.ai - ООО "Цифровой онкоморфолог", является резидентом <i class="mark">Сколково.</i>'
  ],
  'about_text_2_0': ['Advantages of implementing digital pathomorphology', 'Преимущества внедрения цифровой патоморфологии'],
  'about_text_2_1': [
    'The conversion of pathomorphology into digital form allows a medical institution to receive a number of advantages:',
    'Перевод патоморфологии в электронный вид позволяет получить медучреждению целый ряд преимуществ:'
  ],
  'about_text_2_2': [
    '<i class="mark">Less eye fatigue, higher concentration</i><i class="block">first few weeks of histology samples observation on the PC are assessed by doctors as "unusual". But after a several weeks of working with scanned images, experts note: the eyes get less tired, concentration lasts longer, and work productivity increases.</i>',
    '<i class="mark">Меньше усталость глаз, выше концентрация</i><i class="block">Обзор изображений гистопрепаратов на экране ПК в первые несколько недель врачи оценивают как "непривычно". Но уже через несколько недель работы с отсканированными изображениями специалисты отмечают: меньше устают глаза, дольше выдерживается концентрация, повышается продуктивность работы.</i>'
  ],
  'about_text_2_3': [
    '<i class="mark">Less eye fatigue, higher concentration</i><i class="block">The digital ruler, the fast report creation in a few mouse clicks, the image region marking allow to instantly see important areas. Tagging and full-text search allows to find reference case with similar conclusions in a few seconds and compare tumor patterns via split-view</i>',
    '<i class="mark">Новые цифровые инструменты, повышающие продуктивность и качество работы</i><i class="block">Цифровая линейка, возможность быстрого формирования заключения в несколько кликов мышкой, выделение и маркировка областей на изображении позволяют моментально увидеть важные области. Тэгирование и полнотекстовый поиск позволяют за нескольких секунд найти референсный случай с похожим заключением и сравнить паттерны опухоли в режиме раздельного просмотра (split-view).</i>'
  ],
  'about_text_2_4': [
    `<i class="mark">Centralized storage of the digital pathomorphological samples</i><i class="block">An archive database with the ability of instant search and view. Automatic image loading from digital slide scanners and automatic attachment to the patient's record in the LIS to achieve possibility of displaying the digital histology slide directly from the LIS with one click.</i>`,
    '<i class="mark">Централизованное хранение патоморфологических препаратов</i><i class="block">Архивная база с возможностью моментального поиска и просмотра. Автоматическая загрузка изображений с гистосканеров и автоматическое прикрепление к записи пациента в ЛИС так, что в дальнейшем появляется возможность отобразить гистопрепарат непосредственно из ЛИС одним кликом. Возможность отправить защищенную ссылку на просмотр гистопрепарата коллеге или пациенту.</i>'
  ],
  'about_text_2_5': [
    '<i class="mark">Pathomorphological laboratory workflow automation</i><i class="block">Specialists get the opportunity of the remote work. The distributed structure of doctors allows you to attract the most suitable specialists to specific cases.</i>',
    '<i class="mark">Автоматизация процессов работы патоморфологической лаборатории</i><i class="block">Специалисты получают возможность работы удаленно. Распределенная структура врачей позволяет привлекать к конкретным случаям наиболее подходящих специалистов.</i>'
  ],
  'about_text_2_6': [
    `<i class="mark">The use of innovative AI-powered diagnostic methods</i><i class="block">PathVision.ai - is the only digital pathology platform on the Russian market that allows of AI usage and has a corresponding Registration Number of the type 274840.</i><i class="block">This is a Russian product created by a Skolkovo resident company, which is included in the register of the Russian Federation software and does not require any any third-party licensed software for its operation. PathVision.ai works on Linux servers (including Astra Linux).</i><i class="block">Thus PathVision.ai – a platform that not only solves today's problems, but also provides a foundation for future development, including the introduction of AI.</i>`,
    '<i class="mark">Использование инновационных методов диагностики с использованием ИИ</i><i class="block">PathVision.ai – единственная платформа цифровой патоморфологии на российском рынке, позволяющая использовать ИИ и имеющая соответствующее РУ вида 274840. Платформа должна предоставлять такой сценарий использования ИИ, который не создает рисков снижения качества работы специалистов.</i><i class="block">Это российский продукт, созданный компанией – резидентом Сколково, входящий в реестр отечественного ПО и не требующий для свой работы какого-либо платного ПО. PathVision.ai работает на серверах Linux (включая Astra Linux).</i><i class="block">Таким образом PathVision.ai – платформа, которая не только решает актуальные задачи сегодняшнего дня, но и дает задел на будущее развитие, включая внедрение ИИ.</i>'
  ],
  // 'about_text_2_1': [
  //   '<i class="mark"></i><i class="block"></i>',
  //   '<i class="mark"></i><i class="block"></i>'
  // ],
  // 'about_text_2_1': [
  //   '<i class="mark"></i><i class="block"></i>',
  //   '<i class="mark"></i><i class="block"></i>'
  // ],
  // 'about_text_2_1': [
  //   '<i class="mark"></i><i class="block"></i>',
  //   '<i class="mark"></i><i class="block"></i>'
  // ],

  'contacts_h1': ['Contact us', 'Свяжитесь с нами'],
  'contacts_pre_value': ['The Company operates in information technology (solution development based on artificial intelligence)', 'Компания осуществляет деятельность в сфере информационных технологий (разработка решений на основе искусственного интеллекта)'],
  'contacts_address_label': ['Address', 'Адрес'],
  'contacts_address_value': ['117105, Varshavskoye Highway, 33 Bldg. 12, Floor 1, Room #3, Moscow, Russia', '117105, г. Москва, Варшавское шоссе, 33с12, Этаж 1, Пом. №3'],
  'contacts_phone_label': ['Phone', 'Телефон'],
  'contacts_email_label': ['Email', 'Почта'],
  'contacts_name_label': ['Company name', 'Наименование юридического лица'],
  'contacts_name_value': ['OOO Tsifrovoy Oncomorpholog', 'ООО Цифровой онкоморфолог'],
  'contacts_itn_label': ['ITN', 'ИНН'],

  'ui_close': ['Close', 'Закрыть'],
  'ui_documents': ['Documents', 'Документы'],
  'ui_documents_manual': ['PathVision. Installation and operation instructions [RU]', 'PathVision. Инструкции по установке и эксплуатации'],
  'ui_documents_offer': ['PathVision. Offer [RU]', 'PathVision. Коммерческое предложение'],
  'ui_documents_functional': ['PathVision. Functional description [RU]', 'PathVision. Функциональное описание'],
  'ui_documents_stack': ['PathVision. Technology stack [RU]', 'PathVision. Технологический стек'],

  'ui_title_news_cnews01': ['AI has started to diagnose cancer', 'ИИ приступил к диагностике рака'],
  'ui_title_news_cnews02': ['A new step in medicine: an innovative AI platform for prostate cancer diagnosis', 'Новый шаг в медицине: инновационная платформа с ИИ для диагностики рака предстательной железы'],
  'ui_title_news_ta01': ['Gennady Popov, PathVision.ai : How artificial intelligence helps medicine and business', 'Геннадий Попов, PathVision.ai: Как искусственный интеллект помогает медицине и бизнесу']
}

const translate = (val, l) => { return dict[val][l] ? dict[val][l] : dict[val][0] ? dict[val][0] : dict[val]; }

const init = {
  lang: 1
}

const lang = {
  en: 0,
  ru: 1,
  //0: en,
  //2: ru,
  
  lang: {
    0: { index: 0, name: 'English', code: 'en', region: 'US' },
    1: { index: 1, name: 'Русский', code: 'ru', region: 'RU' }
  },

  get(name) { return name ? this[name] : init.lang; },
  displayName(i) { return this.lang[i].code; },
  //locale: ['en-US', 'ru-RU'],
  //convertDate
}

function handleScroll(el, last) {
  //if (window.scrollY > 150) {
  if (window.innerHeight > el.getBoundingClientRect().top + 150) {
    el.classList.add('scrolled');
  }
  else {
    if (el.classList.contains('scrolled')) el.classList.remove('scrolled');
  }
  return false; //(window.innerHeight > (el.getBoundingClientRect().top + 100) && last) ? true : false; //window.scrollY > 200
}


export const service = {
  init,
  lang,
  translate,
  handleScroll
};
