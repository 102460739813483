<template>
  <section id="contacts" class="section flex vcenter hcenter column">
    <div v-scroll="handleScroll" class="title">{{ translate('menu_contacts') }}</div>
    <div v-scroll="handleScroll" class="container vscroll-b">
      <div class="columns flex">
        <div class="elastic vcenter">
          <!--div-->
            <h1 class="h1">{{ translate('contacts_h1') }}</h1>
            <div class="address-container">
              <div v-for="(a, i) in address" :key="i" class="address">
                <label class="label">{{ a.label }}</label>
                <div v-if="a.link" class="value">
                  <a class="link" :href="a.link">{{ a.value }}</a>
                </div>
                <div v-else class="value">{{ a.value }}</div>
              </div>
            </div>
          <!--/div-->
        </div>
      </div>
    </div>
    <div v-scroll="handleScroll" class="map-container vscroll-o" v-html="map"></div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import { service } from '../store/service'

export default {
  name: 'Contacts',

  data() {
    return { }
  },

  computed: {
    ...mapGetters(['currentLang', 'translate']),

    address() {
      return [
        {
          // label: this.translate('contacts_address_label'),
          value: this.translate('contacts_pre_value')
        },
        {
          label: this.translate('contacts_address_label'),
          value: this.translate('contacts_address_value')
        },
        {
          label: this.translate('contacts_phone_label'),
          value: '+ 7 495 269 07 38'
        },
        {
          label: this.translate('contacts_email_label'),
          value: 'info@pathvision.ai',
          link: 'mailto: info@pathvision.ai'
        },
        {
          label: this.translate('contacts_name_label'),
          value: this.translate('contacts_name_value')
        },
        {
          label: this.translate('contacts_itn_label'),
          value: '9726025611'
        }
      ]
    },

    // Yandex
    // <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A9c60ac3483c8f211d612554bf8bb675f3b16ada2d82ecf8fba226b2bb1212a68&amp;source=constructor" width="100%" height="700" frameborder="0"></iframe>
    map() {
      return `<div class="mapouter">
        <div class="gmap_canvas">
          <a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Москва</a><a href="https://yandex.ru/maps/213/moscow/house/varshavskoye_shosse_33s12/Z04YcAViSkIPQFtvfXp4cHtmYQ==/?ll=37.619428%2C55.694103&utm_medium=mapframe&utm_source=maps&z=15.68" style="color:#eee;font-size:12px;position:absolute;top:14px;">Варшавское шоссе, 33с12 — Яндекс Карты</a><iframe src="https://yandex.ru/map-widget/v1/?ll=37.619428%2C55.694103&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjY3MzUzNhJD0KDQvtGB0YHQuNGPLCDQnNC-0YHQutCy0LAsINCS0LDRgNGI0LDQstGB0LrQvtC1INGI0L7RgdGB0LUsIDMz0YExMiIKDVF9FkIVXMReQg%2C%2C&z=15.68&lang=${this.currentLang.code}_${this.currentLang.region}" width="100%" height="700" frameborder="0" allowfullscreen="true" style="position:relative;"></iframe>
        </div>
        <style>
        </style>
      </div>`
    }

    // Google
    // OLD <iframe width="100%" height="700" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7563.910434358784!2d37.60952100626416!3d55.69308863005566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54b542b924a5f%3A0x7e892e82f2b45517!2z0JLQsNGA0YjQsNCy0YHQutC-0LUg0YguLCAzMyDRgdGC0YDQvtC10L3QuNC1IDEyLCDQnNC-0YHQutCy0LAsIDExNzEwNQ!5e0!3m2!1sru!2sru!4v1710200349232!5m2!1sru!2sru&t=&z=15&ie=UTF8&hl=${this.currentLang.name}&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    // map() {
    //   return `<div class="mapouter">
    //     <div class="gmap_canvas">
    //       <iframe width="100%" height="700" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7563.910434358784!2d37.60952100626416!3d55.69308863005566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54b542b924a5f%3A0x7e892e82f2b45517!2z0JLQsNGA0YjQsNCy0YHQutC-0LUg0YguLCAzMyDRgdGC0YDQvtC10L3QuNC1IDEyLCDQnNC-0YHQutCy0LAsIDExNzEwNQ!5e0!3m2!1sru!2sru!4v1710200548508!5m2!1s${this.currentLang.code}!2s${this.currentLang.code}" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    //     </div>
    //     <style>
    //     </style>
    //   </div>`
    // }
  },

  methods: {
    handleScroll(e, el) {
      //console.log('c handleScroll:', window.innerHeight, window.scrollY, window.innerHeight + window.scrollY, el.getBoundingClientRect());
      return service.handleScroll(el, true);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorSectionBg: #777777;
@colorArrowBg: #676767;
@colorTitle: #717171;

.home {
  .section {
    background-color: @colorBgContacts;

    .title {
      color: @colorTitle;
    }

    /*.mark {
      background-color: @colorMarkBg;
    }*/

    .address-container {
      padding: 20px 0;

      .address {
        padding-bottom: 30px;

        .label {
          font-size: 0.7em;
          color: #bbb;
        }

        .value {
          font-size: 1.1em;
          color: #fff;

          .link {
            color: #76bbff;
            .pv-transition-color();

            &:hover { color: #55aaff; }
          }
        }
      }
    }
  }
}

.map-container::v-deep {
  position: relative;
  width: 100%;
  z-index: 100;

  .mapouter{
    position: relative;
    text-align: right;
    height: 700px;
    width:100%;

    .gmap_canvas {
      overflow:hidden;
      background: none !important;
      height: 700px;
      width: 100%;
    }
  }
}

</style>