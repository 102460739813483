<template>
  <div class="home">
    <Modal />
    <div class="fixedbar">
      <div class="container">
        <div class="docs" @click="toggleShowModal">{{ translate('ui_documents') }}</div>
        <LangControl />
      </div>
    </div>
    <transition name="fade-slide" @after-enter="afterEnter">
      <div class="sections" :key="currentLangIndex">
        <Main />
        <Approach />
        <NewStep />
        <Partners />
        <About />
        <Contacts />
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import TopBar from '@/components/TopBar.vue';
import Main from '@/components/Main.vue';
import Approach from '@/components/Approach.vue';
import NewStep from '@/components/NewStep.vue';
import Partners from '../components/Partners.vue';
import About from '@/components/About.vue';
import Contacts from '../components/Contacts.vue';
import LangControl from '@/components/LangControl.vue'
import Modal from '@/components/Modal.vue';
import { mapGetters, mapMutations } from 'vuex';
import { service } from '../store/service';

export default {
  name: 'Home',

  components: {
    TopBar,
    Main,
    Approach,
    NewStep,
    Partners,
    About,
    Contacts,
    LangControl,
    Modal
  },

  computed: {
    ...mapGetters(['currentLangIndex', 'translate']),
  },

  created() {
    const { lang } = this.$route.params;
    this.setLang(service.lang.get(lang));
  },

  methods: {
    ...mapMutations(['changeLang', 'toggleShowModal', 'setLang']),

    afterEnter(el) { this.changeLang(); }
  }
}
</script>

<style lang="less">

@import '../assets/vars.less';

.home {
  //position: relative;
  //padding-top: 60px;

  .docs {
    position: absolute;
    height: @langElementHeight;
    font-size: 0.7em;
    font-weight: 500;
    right: 6em;
    background-color: @colorLink;
    padding: 0 @langElementHPadding;
    border-bottom-left-radius: @langControlRadius;
    border-bottom-right-radius: @langControlRadius;

    @media @phone { font-size: 1em; }

    &:hover {
      background-color: @colorLinkHover;
      cursor: pointer;
      .pv-transition-color();
    }
  }

  .sections { width: 100%; }

  .section {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    margin-left: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      //width: 100vw;
      //max-width: 0;
      width: 0;
      height: 0;
      bottom: 0;
      left: 0;
    }

    .columns { padding-bottom: 5em; }
    .h1 { margin-top: 5%; }
    .mark { font-style: normal; }
  
    .title {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      margin-top: -3%;
      margin-left: 100%;
      overflow: hidden;
      //font-family: 'Gruppo', 'Montserrat Alternates', sans-serif; //'Gruppo', cursive;
      //font-family: 'Exo 2', sans-serif;
      font-weight: 600;
      // font-size: 19.85vw;
      font-size: 16vw;
      z-index: 0;
      white-space: nowrap;
      transform: scaleY(0.7);
      .pv-transition-pos(0.7s);

      &.right { margin-left: -100%; }
    
      &.scrolled { margin-left: 0; }
    }

    .vscroll-b {
      margin-top: 100px;
      opacity: 0;
      transform: scale(0.8);
      //.pv-transition-pos-bezier(1s);
      .pv-transition-all(1s);

      &.scrolled {
        margin-top: 0;
        opacity: 1;
        transform: none;
      }
    }

    .vscroll-l {
      margin-left: -100%;
      opacity: 0;
      //.pv-transition-pos-bezier(1s);
      .pv-transition-all(1s);

      &.scrolled {
        margin-left: 0;
        opacity: 1;
      }
    }

    .vscroll-r {
      margin-left: 100%;
      opacity: 0;
      //.pv-transition-pos-bezier(1s);
      .pv-transition-all(1s);

      &.scrolled {
        margin-left: 0;
        opacity: 1;

        .vscroll-r1, .vscroll-r2 {
          margin-left: 0;
          margin-top: 0;
        }
      }

      .vscroll-r1 {
        margin-left: 20%;
        margin-top: -10%;
        .pv-transition-all(1.3s);
      }

      .vscroll-r2 {
        margin-left: 30%;
        margin-top: -10%;
        .pv-transition-all(1.5s);
      }
    }

    .vscroll-o {
      opacity: 0;
      .pv-transition-all(1s);

      &.scrolled { opacity: 1; }
    }

    .text {
      //font-family: 'IBM Plex Sans', sans-serif; //'Roboto'
      color: #aaaaaa;

      &.justify { text-align: justify; }

      &:hover {
        .mark {
          color: #111111;
        }
      }

      .mark {
        color: @colorMark;
        .pv-transition-color();
      }
    }
  }
}

.fixedbar {
  position: fixed;
  width: 100%;
  top: 0;
  padding: 0 30px;
  z-index: 1000;

  @media @phone {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.fade-slide-enter-active, .fade-slide-leave-active {
  .pv-transition(margin 0.7s, opacity 1s;);
  position: absolute;
}

.fade-slide-enter, .fade-slide-leave-to {
  opacity: 0;
  margin-left: -110%;
}

</style>