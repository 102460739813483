<template>
  <section id="approach" class="section flex vcenter hcenter column">
    <div v-scroll="handleScroll" class="title">{{ translate('menu_approach') }}</div>
    <div v-scroll="handleScroll" class="container vscroll-b">
      <div class="columns flex">
        <div class="elastic flex vcenter">
          <div>
            <h1 class="h1">{{ translate('approach_h1') }}</h1>
            <div class="text" v-html="translate('approach_text')"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-scroll="handleScroll" class="compare">
      <!--TwentyTwenty
        offset="0.5"
        before="//placehold.it/600x200/E8117F/FFFFFF"
        beforeLabel="BEFORE"
        after="//placehold.it/600x200/CCCCCC/FFFFFF"
        afterLabel="AFTER"
      /-->
      <TwentyTwenty
        offset="0.5"
        before="/resources/before.jpg"
        after="/resources/after.jpg"
        :key="langChanged"
      />
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import TwentyTwenty from '@/components/TwentyTwenty.vue';
import { mapGetters } from 'vuex';
import { service } from '../store/service'

export default {
  name: 'Approach',

  components: {
    TwentyTwenty
  },

  data() {
    return {
      offset: 0
    }
  },

  computed: {
    ...mapGetters(['translate', 'langChanged'])
  },

  methods: {
    handleScroll(e, el) { return service.handleScroll(el); }
    /*handleScroll: function (evt, el) {
      console.log('approach handleScroll:', el.getBoundingClientRect().top, window.scrollY);
      if (window.scrollY > el.getBoundingClientRect().top) {
      //if (window.scrollY > 150) {
        el.classList.add('scrolled');
      }
      return false; //window.scrollY > 200
    }*/
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorMarkBg: #ffffaa99;
@colorSectionBg: #444444;
@colorArrowBg: #555555;
@colorTitle: #3f3f3f;


.section {
  background-color: @colorSectionBg;
  overflow: hidden;

  .title { color: @colorTitle; }

  .compare {
    padding-bottom: 70px;
    width: 100%;
    position: relative;
    margin-top: 100px;
    opacity: 0;
    .pv-transition-all(1.5s);

    &.scrolled {
      margin-top: 0;
      opacity: 1;
    }
  }

  &::after {
    border-top: 50px solid transparent;
    border-right: 30vw solid @colorArrowBg;
    border-left: 70vw solid @colorArrowBg;
  }

  .text::v-deep {
    .mark {
      background-color: @colorMarkBg;
      .mark();
    }
  }
}


</style>