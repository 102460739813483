<template>
  <transition name="fade-shift">
    <div class="modal-wrapper flex center" v-if="showModal">
      <div class="modal">
        <div class="row flex hend">
          <div class="btn flex center link" @click="toggleShowModal(false)" :title="translate('ui_close')">
            <font-awesome-icon icon="times" />
          </div>
        </div>

        <!-- <div v-scroll="handleScroll" class="title">{{ translate('ui_documents') }}</div> -->
        <div v-scroll="handleScroll" class="container vscroll-b">
          <div class="flex column">
            <!-- <div class="elastic vcenter"> -->
              <h1 class="h1">{{ translate('ui_documents') }}</h1>
              <a href="/resources/PathVision.Manual.pdf" class="h3 link" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon icon="download" />
                {{ translate('ui_documents_manual') }}
              </a>
              <a href="/resources/PathVision.Offer.pdf" class="h3 link" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon icon="download" />
                {{ translate('ui_documents_offer') }}
              </a>
              <a href="/resources/PathVision.Functional.pdf" class="h3 link" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon icon="download" />
                {{ translate('ui_documents_functional') }}
              </a>
              <a href="/resources/PathVision.Stack.pdf" class="h3 link" target="_blank" rel="noopener noreferrer">
                <font-awesome-icon icon="download" />
                {{ translate('ui_documents_stack') }}
              </a>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapMutations } from 'vuex';
import { service } from '../store/service'

export default {
  name: 'Modal',

  data() {
    return { }
  },

  computed: {
    ...mapGetters(['currentLang', 'translate', 'showModal']),

    address() {
      return [
        {
          label: this.translate('contacts_address_label'),
          value: this.translate('contacts_address_value')
        },
        {
          label: this.translate('contacts_phone_label'),
          value: '+ 7 495 269 07 38'
        },
        {
          label: this.translate('contacts_email_label'),
          value: 'info@pathvision.ai',
          link: 'mailto: info@pathvision.ai'
        }
      ]
    }
  },

  methods: {
    ...mapMutations(['toggleShowModal']),

    handleScroll(e, el) {
      //console.log('c handleScroll:', window.innerHeight, window.scrollY, window.innerHeight + window.scrollY, el.getBoundingClientRect());
      return service.handleScroll(el, true);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorSectionBg: #777777;
@colorArrowBg: #676767;
@colorTitle: #717171;

.modal-wrapper {
  position: fixed;
  backdrop-filter: blur(10px);
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .modal {
    background-color: #555555ee;
    border-radius: 20px;
    padding: 20px;
    width: 80%;
    height: 80%;
    margin-top: 0;

    .row {
      width: 100%;

      .btn {
        font-size: 40px;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.fade-shift-enter-active, .fade-shift-leave-active {
  .pv-transition(opacity 0.5s);
  // position: absolute;

  .modal {
    //.pv-transition-pos(0.5s);
    .pv-transition(margin 0.5s, transform 0.5s;);
    margin-top: 0;
    transform: none;
  }
}

.fade-shift-enter, .fade-shift-leave-to {
  opacity: 0;

  .modal {
    margin-top: -25%;
    transform: scale(1.05);
  }
}

</style>