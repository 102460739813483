<template>
  <section id="new-step" class="section flex vcenter hcenter column">
    <div v-scroll="handleScroll" class="title">{{ translate('menu_newstep') }}</div>
    <div v-scroll="handleScroll" class="container vscroll-b">
      <div class="columns flex">
        <div class="elastic flex vcenter">
          <div>
            <h1 class="h1">{{ translate('newstep_h1') }}</h1>
            <div class="text" v-html="translate('newstep_text')"></div>
          </div>
        </div>
      </div>
    </div>
    <Steps v-scroll="handleScroll" class="vscroll-l" />
  </section>
</template>

<script>
// @ is an alias to /src
import Steps from '@/components/Steps.vue';
import { mapGetters } from 'vuex';
import { service } from '../store/service'

export default {
  name: 'NewStep',

  components: {
    Steps
  },

  data() {
    return {
      step: 1
    }
  },

  computed: {
    ...mapGetters(['translate'])
  },

  methods: {
    nextStep() {
      this.step = this.step > 2 ? 1 : this.step + 1;
      console.log(this.step);
    },

    handleScroll(e, el) { return service.handleScroll(el); }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorMarkBg: #aaffaa99;
@colorSectionBg: #555555;
@colorArrowBg: #676767;
@colorTitle: #4f4f4f;

.home {
  .section {
    background-color: @colorSectionBg;

    &::after {
      border-top: 50px solid transparent;
      border-right: 50vw solid @colorArrowBg;
      border-left: 50vw solid @colorArrowBg;
    }

    .title {
      color: @colorTitle;
    }

    .text::v-deep {
      .mark {
        background-color: @colorMarkBg;
        .mark();
      }
    }
  }
}

</style>