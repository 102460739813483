<template>
  <div class="topbar">
    <div :class="['container flex vcenter', { shaded: scrollPosition > 200 }/*, { blue: scrollPosition > 3300 }*/]">
      <scrollactive class="nav flex space center">
        <div class="pv-logo-container flex vcenter elastic">
          <!-- <a href="#main" class="pv-logo scrollactive-item link" v-html="pvLogoSvg"></a> -->
          <a href="#main" class="pv-logo scrollactive-item link">
            <PvIcon icon="pvlogo" param="fill logo" viewBox="0 0 23400 3800" />
          </a>
        </div>
        <transition name="fade">
          <div class="menu flex center" :key="currentLangIndex">
            <a href="#main" class="scrollactive-item link">{{ translate('menu_news') }}</a>
            <a href="#approach" class="scrollactive-item link">{{ translate('menu_approach') }}</a>
            <a href="#new-step" class="scrollactive-item link">{{ translate('menu_newstep') }}</a>
            <a href="#partners" class="scrollactive-item link">{{ translate('menu_partners') }}</a>
            <a href="#about" class="scrollactive-item link">{{ translate('menu_about') }}</a>
            <a href="#contacts" class="scrollactive-item link">{{ translate('menu_contacts') }}</a>
          </div>
        </transition>
      </scrollactive>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import PvIcon from '@/components/PvIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TopBar',

  components: {
    PvIcon
  },

  props: { },

  data() {
    return {
      scrollPosition: null
    }
  },

  computed: {
    ...mapGetters(['pvLogoSvg', 'translate', 'currentLangIndex'])
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    }
  },

  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },

  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../assets/vars.less';

@colorTopbarBg: #333333e1;
@colorTopbarBgBlue: #4488cce1;
@colorTopbarBlue: #444444;
@colorLinkBlue: #aaaaaa; //#76bbff;

.topbar {
  //height: 60px;
  padding: 0 30px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  //box-sizing: border-box;
  z-index: 1000;

  .container {
    padding-top: 1.3em;
    padding-bottom: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .pv-transition-all(0.7s);

    &.shaded {
      padding-top: 20px;
      box-shadow: 0 0 40px 0 #222222;
      background-color: @colorTopbarBg;

      &.blue {
        background-color: @colorTopbarBgBlue;
      
        .nav {
          .pv-logo-container {
            .pv-logo {
              fill: @colorTopbarBlue;

              &:hover { fill: @colorLinkBlue; }
            }
          }

          .menu {
            .link {
              color: @colorTopbarBlue;

              &:hover, &.is-active { color: @colorLinkBlue; }
            }
          }
        }
      }

      @media @phone {
        padding: 0.2em 0 0;

        .nav {
          .pv-logo-container {
            justify-content: flex-start;
            flex-grow: 0;

            .pv-logo {
              padding: 0 0.2em;
              width: auto;

              .logo { width: 30vw; }
            }
          }
        }
      }

      /*.nav {
        @media @phone {
          height: 10vw;
          overflow: hidden;
        }
      }*/
    }

    .nav {
      //padding: 0px 30px;
      //font-family: 'Gruppo', 'Montserrat Alternates', sans-serif; //'Gruppo', cursive;
      //font-family: 'Roboto', sans-serif; //'Exo 2'
      //font-weight: 300;
      width: 100%;
      position: relative;

      .pv-logo-container {
        line-height: 1;
        .pv-transition-all(0.7s);

        .pv-logo {
          // width: 15vw;
          padding: 0 7.5px;
          fill: @colorLink;
          .pv-transition-color();

          .logo {
            width: 10em;
            .pv-transition-all(0.7s);
          }
          &:hover, &.is-active { fill: @colorLinkHover; }

          // @media @phone1 { width: 60vw; }
          // @media @phone2 { width: 36vw; }
          // @media @tablet { width: 25vw; }
        }

        @media @phone { justify-content: center; }
      }

      .menu {
        margin-right: 1em;
        transform: scaleY(0.8);
        font-size: 1.1em;

        .link {
          margin: 0 0.5em;
          text-decoration: none;
          //text-transform: uppercase;
          color: #aaaaaa;
          white-space: nowrap;
          .pv-transition-color();

          &:hover, &.is-active { color: @colorLinkHover; }

          @media @phone { padding-bottom: 5px; }
        }

        @media @phone {
          display: none;
          // flex-grow: 1;
          // margin-right: 0;
        }
      }

      @media @phone { padding: 0; }
      @media @phone2 { font-size: 0.9em; }
    }

    @media @phone {
      flex-direction: column;
    }
  }

  @media @phone {
    font-size: 1.25em;
    //line-height: 1.5;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.fade-enter-active, .fade-leave-active {
  .pv-transition(opacity 1s;);
}

.fade-leave-active {
  position: absolute;
  right: 0;

  @media @phone { bottom: 0; }
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  //margin-left: -110%;
}

</style>
