<template>
  <div id="main" class="section flex center">
    <div class="prost-bg"></div>
    <div v-scroll="handleScroll" class="title">PathVision</div>
    <div class="container">
      <div class="news-container">
        <h1 class="h1">{{ translate('menu_news') }}</h1>
        <div class="news-inner flex center gap">
          <div class="img-news flex">
            <a
              href="https://www.cnews.ru/articles/2024-08-06_ii_pristupil_k_diagnostike_raka?erid=LjN8KQs9i"
              target="_blank"
              rel="noopener noreferrer"
              class="link img-link bg"
              :title="translate('ui_title_news_cnews01')"
            >
              <img src="../assets/cnews.logo01.svg" class="img-press" />
            </a>
          </div>
          <!-- <div class="img-news flex">
            <a href="https://www.cnews.ru/news/line/2024-08-06_novyj_shag_v_meditsine_innovatsionnaya" class="link img-link bg" :title="translate('ui_title_news_cnews02')">
              <img src="../assets/cnews.logo01.svg" class="img-press" />
            </a>
          </div> -->
          <div class="img-news flex">
            <a
              href="https://www.tadviser.ru/index.php/%D0%A1%D1%82%D0%B0%D1%82%D1%8C%D1%8F:%D0%93%D0%B5%D0%BD%D0%BD%D0%B0%D0%B4%D0%B8%D0%B9_%D0%9F%D0%BE%D0%BF%D0%BE%D0%B2%2C_PathVision.ai:_%D0%9A%D0%B0%D0%BA_%D0%B8%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B9_%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82_%D0%BF%D0%BE%D0%BC%D0%BE%D0%B3%D0%B0%D0%B5%D1%82_%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D0%B5_%D0%B8_%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81%D1%83"
              target="_blank"
              rel="noopener noreferrer"
              class="link img-link"
              :title="translate('ui_title_news_ta01')"
            >
              <img src="../assets/press.ta.logo.png" class="img-press" />
            </a>
          </div>
        </div>
      </div>
      <div class="columns flex">
        <div class="elastic flex vcenter">
          <div>
            <h1 class="h1">{{ translate('main_h1') }}</h1>
            <div class="text" v-html="translate('main_text')"></div>
          </div>
        </div>
        <div class="img-container elastic flex center">
          <img src="../assets/prost16xr.png" class="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import { service } from '../store/service'

export default {
  name: 'Main',

  computed: {
    ...mapGetters(['translate'])
  },

  methods: {
    handleScroll(e, el) { return service.handleScroll(el); }
  }
}
</script>

<style lang="less" scoped>

@import '../assets/vars.less';

//@colorMarkBg: #ffaaff99;
@colorArrowBg: #444444;
@colorTitle: #383838;

.home {
  .section {
    min-height: 100vh;

    &::after {
      border-top: 50px solid transparent;
      border-right: 70vw solid @colorArrowBg;
      border-left: 30vw solid @colorArrowBg;
    }

    .container {
      @media @tablet { padding-top: 5em; }

      .news-container {
        width: 100%;
        padding-bottom: 2vh;

        .news-inner {
          height: 10vw;
          max-height: 12vh;

          .img-news {
            height: 100%;
            max-width: 15vw;

            @media @phone { max-width: calc(50% - 1vw); }
            // @media @phone2 { max-width: 30vw; }

            .link {
              height: 100%;
              display: flex;

              .img-press { width: 100%; }
            }
          }

          @media @phone {
            height: unset;
            max-height: unset;
          }

          // @media @phone2 {
          //   height: 20vw;
          //   max-height: 24vw;
          // }
        }
      }

      .columns {
        // padding-bottom: 0;

        .img-container {
          padding: 15px;
          position: relative;
          max-width: 50%;
          
          .img {
            width: 100%;
            min-width: 270px;
            max-width: 650px;
            border-radius: 50%;
          }

          @media @phone {
            max-width: 100%;
            margin-top: 15px;
          }
        }

        @media @phone {
          flex-direction: column;
        }
      }

      @media @phone {
        padding-top: 20vh;
        padding-bottom: 10%;
      }
    }

    .prost-bg {
      background-image: url('../assets/prost.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      position: absolute;
      filter: grayscale(1) blur(3px);
      opacity: 0.1;
    }

    .text::v-deep {
      .mark {
        background-color: @colorMarkBg;
        .mark();
      }
    }

    .title {
      top: 100px;
      color: @colorTitle;
      margin-left: 0;
    }

    .h1 { margin-top: 0; }
  }
}

</style>