import Vue from 'vue'
import Vuex from 'vuex'
import { service } from './service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentLangIndex: service.init.lang,
    langs: service.lang.lang,
    langChanged: false,
    showModal: false,
    pvLogoSvg: `<svg version="1.1" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px" y="0px" viewBox="0 0 150 26" class="pv-svg-logo">
      <g>
        <path class="pv-logo-0" d="M111.6,22.1h2.1v-9.2c0-0.9,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7v5.6c0,2.1,1.8,3.9,3.9,3.9c2.1,0,3.9-1.7,3.9-3.9
          v-9h-2.1v9c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8v-5.6c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8
          C111.6,12.9,111.6,22.1,111.6,22.1z"/>
        <path class="pv-logo-0" d="M0,22.1V3.5h7.8c1.2,0,2.2,0.3,3.1,0.8c0.9,0.5,1.7,1.2,2.3,2.2c0.6,0.9,0.8,2,0.8,3.3c0,1.2-0.3,2.3-0.8,3.2
          C12.7,14,12,14.8,11,15.3c-0.9,0.5-2,0.8-3.2,0.8H2.5v5.9H0L0,22.1z M2.5,13.8h4.9c0.8,0,1.4-0.2,2.1-0.5c0.6-0.3,1.1-0.8,1.5-1.4
          c0.4-0.6,0.5-1.3,0.5-2.1c0-0.8-0.2-1.6-0.5-2.1c-0.4-0.6-0.8-1-1.5-1.4C9,6,8.2,5.8,7.5,5.8h-5V13.8z"/>
        <path class="pv-logo-0" d="M62.1,22.5c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.3-0.8-0.9-1.1-1.6l-6-16.7H56l5.3,15.7c0.1,0.2,0.2,0.4,0.3,0.6
          c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.3-0.6l5.4-15.7h2.7l-6,16.7c-0.3,0.7-0.6,1.2-1.1,1.6
          C63.3,22.3,62.7,22.5,62.1,22.5z"/>
        <rect x="71.6" y="8.6" class="pv-logo-0" width="2.4" height="13.5"/>
        <rect x="90.4" y="8.6" class="pv-logo-0" width="2.4" height="13.5"/>
        <path class="pv-logo-0" d="M102.2,22.5c-1.4,0-2.6-0.3-3.6-0.9S96.7,20,96.1,19c-0.6-1.1-0.9-2.3-0.9-3.6s0.3-2.5,0.9-3.6
          c0.6-1.1,1.4-1.9,2.5-2.5c1.1-0.6,2.3-0.9,3.6-0.9c1.3,0,2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.5,2.5,2.5c0.6,1.1,0.9,2.3,0.9,3.6
          c0,1.3-0.3,2.5-0.9,3.6c-0.6,1.1-1.4,1.9-2.5,2.5C104.8,22.1,103.6,22.5,102.2,22.5z M102.2,20.3c0.9,0,1.7-0.2,2.4-0.7
          c0.7-0.4,1.2-1,1.6-1.8c0.4-0.7,0.6-1.6,0.6-2.5c0-0.9-0.2-1.8-0.6-2.5c-0.4-0.7-0.9-1.3-1.6-1.8c-0.7-0.4-1.5-0.7-2.4-0.7
          s-1.7,0.2-2.4,0.7c-0.7,0.4-1.2,1-1.6,1.8c-0.4,0.7-0.6,1.6-0.6,2.5c0,0.9,0.2,1.8,0.6,2.5c0.4,0.7,0.9,1.3,1.6,1.8
          C100.5,20.1,101.3,20.3,102.2,20.3z"/>
        <path class="pv-logo-0" d="M128.5,22.4c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.7-0.5-1.2s0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5
          c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.4,0.5-0.6,0.6C129.1,22.3,128.8,22.4,128.5,22.4
          z"/>
        <rect x="147.6" y="8.6" class="pv-logo-0" width="2.4" height="13.5"/>
        <path class="pv-logo-0" d="M19.5,11.4c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.6
          l3.7,10.7h2.7l-4.2-11.7c-0.3-0.7-0.7-1.3-1.1-1.6c-0.5-0.3-1-0.5-1.7-0.5c-0.7,0-1.2,0.2-1.7,0.5c-0.5,0.3-0.9,0.9-1.1,1.6
          l-4.3,11.7h2.6L19.5,11.4z"/>
        <path class="pv-logo-0" d="M137.9,11.4c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.3,0.6
          l3.6,10.7h2.7l-4.2-11.7c-0.3-0.7-0.7-1.3-1.1-1.6c-0.5-0.3-1-0.5-1.7-0.5s-1.2,0.2-1.7,0.5c-0.5,0.3-0.9,0.9-1.1,1.6l-4.2,11.7
          h2.6L137.9,11.4z"/>
        <path class="pv-logo-0" d="M31.1,10.7h-4.6V8.6h7h4.6v2.1h-4.6v7.4c-0.2,2.5,3,1.8,4.5,1.9v2h-3.1l0,0c-2.4,0-3.8-1.5-3.8-3.8
          C31.1,18.2,31.1,10.7,31.1,10.7z"/>
        <polygon class="pv-logo-0" points="52.4,22.1 52.4,8.6 50,8.6 50,14.3 43.3,14.3 43.3,8.6 40.7,8.6 40.7,22.1 43.3,22.1 43.3,16.3 
          50,16.3 50,22.1 	"/>
        <path class="pv-logo-0" d="M80.3,16.3h3.9c1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8H77v2l7.2,0.1c2.2,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9
          c0,0-3.9,0-3.9,0c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7h6.9V8.7h-6.9c-2.1,0-3.8,1.7-3.8,3.8S78.2,16.3,80.3,16.3z"/>
      </g>
    </svg>`
  },

  getters: {
    pvLogoSvg: (state) => { return state.pvLogoSvg; },
    currentLangIndex: (state) => { return state.currentLangIndex; },
    currentLang: (state) => { return service.lang.lang[state.currentLangIndex]; },
    langs: (state) => { return Object.values(state.langs).filter(el => el.index !== state.currentLangIndex); },
    langChanged: (state) => { return state.langChanged; },
    translate: (state, getters) => (text) => { return service.translate(text, getters.currentLangIndex); },
    showModal: (state) => { return state.showModal; },
  },

  mutations: {
    setLang(state, val) { state.currentLangIndex = val; },
    changeLang(state) {
      state.langChanged = !state.langChanged;
      window.scrollBy(0, -1);
    },
    toggleShowModal(state, force) { state.showModal = force === undefined ? !state.showModal : force; }
  },

  actions: {
  },

  modules: {
  }
})
