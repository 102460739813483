<template>
  <div class="steps-outer-container flex unwrap vcenter">
    <div class="btn flex center elastic" @click="prevStep">
      <font-awesome-icon icon="chevron-left" />
    </div>
    <div class="steps-container container">
      <div class="steps flex even">
        <div
          v-for="(s, i) in steps"
          :key="i"
          :class="['step flex center', { active: parseInt(i) === curStep }, { pv: steps[curStep].pv }]"
          @click="gotoStep(i)"
        >
          {{ i }}
        </div>
      </div>
      <transition :name="slideDirection">
        <div :class="'step-container elastic columns flex vcenter'" :key="curStep" v-touch:swipe.left="nextStep" v-touch:swipe.right="prevStep">
          <div :class="['icon-container flex vcenter', { 'pv': steps[curStep].pv }]">
            <font-awesome-icon v-if="steps[curStep].icon" :icon="steps[curStep].icon" />
            <!-- <div v-if="steps[curStep].pv" class="pv-logo" v-html="pvLogoSvg"></div> -->
            <div v-if="steps[curStep].pv" class="pv-logo">
              <PvIcon icon="pvlogo" param="logo" viewBox="0 0 23400 3800" />
            </div>
          </div>
          <div class="step-item elastic flex vcenter">
            <div>
            <h2 class="h2 step-title">{{ steps[curStep].title }}</h2>
            <div class="text justify step-text" v-html="steps[curStep].text"></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="btn flex center elastic" @click="nextStep">
      <font-awesome-icon icon="chevron-right" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PvIcon from '@/components/PvIcon.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Steps',

  components: {
    PvIcon
  },

  data() {
    return {
      curStep: 1,
      slideDirection: 'right'
    }
  },

  computed: {
    ...mapGetters(['pvLogoSvg', 'translate']),

    steps() {
      return {
        1: {
          step: 'Step 1',
          icon: 'desktop',
          title: this.translate('steps_title_1'),
          text: this.translate('steps_text_1')
        },
        2: {
          step: 'Step 2',
          icon: 'server',
          pv: true,
          title: this.translate('steps_title_2'),
          //text: 'The whole slide image is sent to the PathVision.ai infrastructure, where it will be <i class="mark">processed by neural networks</i> in a minute. Afterwards, it is transferred back to the specialist <i class="mark">fully classified</i> with a text description, describing the opinion of the neural network on the possible <i class="mark">diagnosis</i>.'
          text: this.translate('steps_text_2')
        },
        3: {
          step: 'Step 3',
          icon: 'notes-medical',
          title: this.translate('steps_title_3'),
          text: this.translate('steps_text_3')
        }
      }
    }
  },

  methods: {
    prevStep() {
      this.slideDirection = 'left';
      this.curStep = this.curStep < 2 ? 3 : this.curStep - 1;
      //console.log(this.curStep);
    },

    nextStep() {
      this.slideDirection = 'right';
      this.curStep = this.curStep > 2 ? 1 : this.curStep + 1;
      //console.log(this.curStep);
    },

    gotoStep(step) {
      let s = parseInt(step);
      this.slideDirection = s > this.curStep ? 'right' : 'left';
      this.curStep = s;
    },

    /*swipePrev() {
      this.slideDirection = 'right';
      this.curStep = this.curStep < 2 ? 3 : this.curStep - 1;
      //console.log(this.curStep);
    },

    swipeNext() {
      this.slideDirection = 'left';
      this.curStep = this.curStep > 2 ? 1 : this.curStep + 1;
      //console.log(this.curStep);
    }*/
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorIcon: #33333340;
@colorIconPv: #4488cc40;
@colorPvLogo: #505050;

.steps-outer-container {
  width: 100%;

  .btn {
    font-size: 60px;
    color: #888888; //@colorLink;
    z-index: 100;
    transform: scaleX(0.7);
    .pv-transition-color();

    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }

  .steps-container {
    padding: 0;

    .steps {
      width: 100%;
      padding: 0.5em 0 0;

      .step {
        width: 4vw;
        height: 4vw;
        border: 0.3vw solid #ffffff;
        border-radius: 50%;
        color: #ffffff;
        font-size: 1.8em;
        user-select: none;
        opacity: 0.25;
        .pv-transition-all-bezier(0.7s);

        &:hover {
          opacity: 1;
          cursor: pointer;
        }

        &.active {
          transform: scale(1.5);
          opacity: 1;
          cursor: default;

          &.pv {
            color: @colorLinkHover;
            border-color: @colorLinkHover;
          }
        }

        @media @phone {
          width: 12vw;
          height: 12vw;
          border-width: 0.9vw;
          font-size: 1.5em;
        }
        @media @tablet {
          width: 6.5vw;
          height: 6.5vw;
          border-width: 0.5vw;
        }
      }
    }

    .step-container {
      width: 100%;
      height: 600px;
      padding: 0 15px 5vh 15px;

      .icon-container {
        position: relative;
        font-size: 20vw;
        color: @colorIcon;
        //opacity: 0.25;

        &.pv { color: @colorIconPv; }

        .pv-logo {
          position: absolute;
          width: 52%;
          left: 8%;
          display: flex;
          fill: @colorPvLogo;
        }

        @media @phone {
          font-size: 50vw;
          margin-top: 5vw;
        }
        @media @tablet { font-size: 30vw; }
      }

      .step-item {
        user-select: none;
        margin-left: -7vw;
        z-index: 10;

        .step-title {
          margin-top: 0;
          color: #ffffff;
        }

        .text::v-deep {
          .mark {
            font-style: normal;
            color: #ffffff;
            //background-color: @colorMarkBg;
          }

          @media @phone1 {
            font-size: 0.9em;
          }
          @media @phone2 {
            font-size: 0.7em;
          }
        }

        @media @phone {
          margin-left: 0;
          margin-top: -40vw;
        }
        @media @tablet { margin-left: -10vw; }
      }

      @media @phone {
        flex-direction: column;
        flex-wrap: nowrap;
        padding-right: 7px;
        padding-left: 7px;
      }
      @media @phone1 {
        height: 500px;
      }
    }
  }
}

.left-enter-active, .left-leave-active,
.right-enter-active, .right-leave-active {
  //.pv-transition-pos(1.25s);
  .pv-transition-all-bezier(0.7s);
}
.left-leave-active, .right-leave-active {
  position: absolute;
}
.left-enter/*, .posstep-leave-to /* .page-leave-active below version 2.1.8 */ {
  margin-left: -55%;
  opacity: 0;
}
.left-leave-to /* .page-leave-active below version 2.1.8 */ {
  margin-left: 150%;
  opacity: 0;
}
.right-enter/*, .posstep-leave-to /* .page-leave-active below version 2.1.8 */ {
  margin-left: 55%;
  opacity: 0;
}
.right-leave-to /* .page-leave-active below version 2.1.8 */ {
  margin-left: -150%;
  opacity: 0;
}

</style>