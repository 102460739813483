<template>
  <section id="partners" class="section flex vcenter hcenter column">
    <div v-scroll="handleScroll" class="title">{{ translate('menu_partners') }}</div>
    <div v-scroll="handleScroll" class="container vscroll-b">
      <h1 class="h1">{{ translate('partners_h1') }}</h1>
    </div>
    <div v-scroll="handleScroll" class="partners-outer vscroll-l">
      <div class="container">
        <div class="columns flex">
          <div class="partners-container flex vcenter even">
            <a v-for="p in partners" class="img-container flex center" :data-text="p.text" :href="p.link" target="_blank" rel="noopener noreferrer">
              <div class="img-inner flex center">
                <img :src="p.logo" class="img" />
              </div>
            </a>

            <!-- <a class="img-container flex center" :data-text="translate('partners_ckb_text')" href="http://www.cchp.ru/">
              <div class="img-inner flex center">
                <img src="../assets/logockb.png" class="img" />
              </div>
            </a>
            <a class="img-container flex center" :data-text="translate('partners_sk_text')" href="https://sk.ru/">
              <div class="img-inner flex center">
                <img src="../assets/sk.icon.svg" class="img" />
              </div>
            </a>
            <a class="img-container flex center upper" :data-text="translate('partners_medtech_text')" href="https://medtech.moscow/">
              <div class="img-inner flex center">
                <img src="../assets/logo.medtech.svg" class="img" />
              </div>
            </a>
            <a class="img-container flex center upper" :data-text="translate('partners_unim_text')" href="https://unim.su/">
              <div class="img-inner pad flex center">
                <img src="../assets/unim.svg" class="img" />
              </div>
            </a> -->

            <!-- <a class="img-container flex center" :data-text="translate('partners_mnioi_text')" href="https://www.mnioi.nmicr.ru">
              <div class="img-inner flex center">
                <img src="../assets/logomn.png" class="img" />
              </div>
            </a>
            <a class="img-container flex center" :data-text="translate('partners_ma_text')" href="https://ma.innoagency.ru/">
              <div class="img-inner flex center">
                <img src="../assets/ma.png" class="img" />
              </div>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import { service } from '../store/service';
// import logoCkb from '../assets/logockb.png';

export default {
  name: 'Partners',

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters(['translate']),

    partners() {
      return {
        ckb: {
          text: this.translate('partners_ckb_text'),
          link: 'http://www.cchp.ru/',
          logo: require('../assets/logockb.png')
        },
        // unim: {
        //   text: this.translate('partners_unim_text'),
        //   link: 'https://unim.su/',
        //   logo: require('../assets/unim.svg')
        // }
        gemotest: {
          text: this.translate('partners_gemotest_text'),
          link: 'https://gemotest.ru/',
          logo: require('../assets/gemotest.logo.jpg')
        },
        mknc: {
          text: this.translate('partners_mknc_text'),
          link: 'https://mknc.ru/',
          logo: require('../assets/mknc.logo.png')
        },
        gkb40: {
          text: this.translate('partners_gkb40_text'),
          link: 'https://mmccdzm.ru/',
          logo: require('../assets/gkb40.logo.svg')
        },
        nmhc: {
          text: this.translate('partners_nmhc_text'),
          link: 'https://www.pirogov-center.ru/',
          logo: require('../assets/nmhc.logo.png')
        },
        nmic: {
          text: this.translate('partners_nmic_text'),
          link: 'https://www.vishnevskogo.ru/',
          logo: require('../assets/nmic.logo.webp')
        },
        yarod: {
          text: this.translate('partners_yarod_text'),
          link: 'https://onco.mzsakha.ru/',
          logo: require('../assets/yarod.png')
        },
        sarood: {
          text: this.translate('partners_sarood_text'),
          link: 'http://sarood2.medportal.saratov.gov.ru/',
          logo: require('../assets/sarood.webp')
        },
        hadassa: {
          text: this.translate('partners_hadassa_text'),
          link: 'https://hadassah.moscow/',
          logo: require('../assets/hadassah.logo.webp')
        },
        ryzhikh: {
          text: this.translate('partners_ryzhikh_text'),
          link: 'https://gnck.ru/',
          logo: require('../assets/nmic.ryzhih.logo.webp')
        },
        sk: {
          text: this.translate('partners_sk_text'),
          link: 'https://sk.ru/',
          logo: require('../assets/sk.icon.svg')
        },
        medtech: {
          text: this.translate('partners_medtech_text'),
          link: 'https://medtech.moscow/',
          logo: require('../assets/logo.medtech.svg')
        }
      }
    }
  },

  methods: {
    handleScroll(e, el) { return service.handleScroll(el); }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

// @colorSectionBg: #676767;
// @colorArrowBg: #777777;
// @colorArrowBg: #ddeeff;
// @colorArrowBg: #cce5ff;
@colorArrowBg: #bbddff;
@colorTitle: #616161;

.home {
  .section {
    background-color: @colorBgPartners;
    //min-height: 75vh;

    .container { max-width: 1600px; }

    &::after {
      border-bottom: 50px solid @colorBgAbout;
      border-right: 50vw solid transparent;
      border-left: 30vw solid transparent;
    }

    .partners-outer {
      width: 100%;

      .partners-container {
        // padding: 70px 0;
        width: 100%;
        min-height: 70vh;

        .img-container {
          width: 40vw;
          height: 40vw;
          max-width: 300px;
          max-height: 300px;
          position: relative;

          &::after {
            content: attr(data-text);
            position: absolute;
            opacity: 0;
            color: #ffffff;
            text-align: center;
            .pv-transition(opacity 0.3s);
          }

          &:hover {
            .img-inner { opacity: 0.15; }

            &::after { opacity: 1; }
          }

          .img-inner {
            width: 20vw;
            height: 20vw;
            max-width: 250px;
            max-height: 250px;
            overflow: hidden;
            border-radius: 50%;
            background-color: #ffffff;
            .pv-transition(opacity 0.3s);

            &.pad { padding: 2vw; }

            .img {
              width: 103%;
              margin-top: -1.5%;
            }

            @media @phone {
              width: 40vw;
              height: 40vw;
            }
          }

          &.upper {
            &::after { text-transform: uppercase; }
          }
          
          @media @phone {
            width: 45vw;
            height: 45vw;
          }
        }
      }
    }

    .title {
      color: @colorTitle;
    }

    /*.mark {
      background-color: @colorMarkBg;
      font-style: normal;
    }*/
  }
}

</style>