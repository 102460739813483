<template>
  <section id="about" class="section flex vcenter hcenter column">
    <div v-scroll="handleScroll" class="title right">{{ translate('menu_about') }}</div>
    <div v-scroll="handleScroll" class="container vscroll-b">
      <h1 class="h1">{{ translate('about_h1_1') }}</h1>
    </div>
    <div v-scroll="handleScroll" class="about-outer vscroll-r">
      <div class="container">
        <div class="columns flex">
          <div class="about-container flex gap">
            <div class="about-column flex column vscroll-r1">
              <div class="about-inner flex column gap h">
                <p class="pf" v-html="translate('about_text_1_0')"></p>
              </div>
              <div class="about-inner flex column gap">
                <p class="pf" v-html="translate('about_text_1_1')"></p>
                <p class="pf" v-html="translate('about_text_1_2')"></p>
                <p class="pf" v-html="translate('about_text_1_3')"></p>
                <p class="pf" v-html="translate('about_text_1_4')"></p>
                <p class="pf" v-html="translate('about_text_1_5')"></p>
                <p class="pf" v-html="translate('about_text_1_6')"></p>
                <p class="pf" v-html="translate('about_text_1_7')"></p>
                <p class="pf" v-html="translate('about_text_1_8')"></p>
                <p class="pf" v-html="translate('about_text_1_9')"></p>
                <p class="pf" v-html="translate('about_text_1_10')"></p>
              </div>
            </div>
            <div class="about-column flex column vscroll-r2">
              <div class="about-inner flex column gap h">
                <p class="pf h" v-html="translate('about_text_2_0')"></p>
              </div>
              <div class="about-inner flex column gap">
                <p class="pf" v-html="translate('about_text_2_1')"></p>
                <p class="pf" v-html="translate('about_text_2_2')"></p>
                <p class="pf" v-html="translate('about_text_2_3')"></p>
                <p class="pf" v-html="translate('about_text_2_4')"></p>
                <p class="pf" v-html="translate('about_text_2_5')"></p>
                <p class="pf" v-html="translate('about_text_2_6')"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex';
import { service } from '../store/service'

export default {
  name: 'About',

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters(['translate'])
  },

  methods: {
    handleScroll(e, el) { return service.handleScroll(el); }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/vars.less';

@colorBgH: #bbaaaa;
@colorBgAboutColumn: #ccbbbb;
@borderRadius: 20px;

.home {
  .section {
    background-color: @colorBgAbout;
    //min-height: 75vh;

    &::after {
      border-bottom: 50px solid @colorBgContacts;
      border-right: 20vw solid transparent;
      border-left: 70vw solid transparent;
    }

    // .container { max-width: 90%; }
    .container { max-width: 1600px; }

    .about-outer {
      width: 100%;

      .gap { gap: 15px; }

      .about-container {
        // padding: 70px 0;
        width: 100%;
        flex-wrap: nowrap;

        @media @phone { flex-wrap: wrap; }

        .about-column {
          flex: 1 0 auto;
          background-color: @colorBgAboutColumn;
          border-radius: @borderRadius;
          max-width: calc(50% - 7.5px);
          font-size: 1.65vh;

          @media @phone { max-width: 100%; }
          
          &.gap { gap: 15px; }

          .about-inner {
            padding: 15px;

            &.h {
              font-size: 1.3em;
              font-weight: 500;
              background-color: @colorBgH;
              border-radius: @borderRadius;
            }
          }

          .pf::v-deep {
            margin: 0;
            color: #333;

            .mark {
              color: #222;
              font-weight: 400;

              &.blue {
                background-color: @colorLinkHover;
                .mark();
                // padding: 0 3px;
                // border-radius: 3px;
              }
            }

            .block {
              display: block;
              // margin-left: 15px;
              font-size: 0.9em;
              padding: 5px 0 5px 15px;
              text-align: justify;
            }
          }
        }

        // .img-container {
        //   width: 40vw;
        //   height: 40vw;
        //   max-width: 350px;
        //   max-height: 350px;
        //   position: relative;

        //   &::after {
        //     content: attr(data-text);
        //     position: absolute;
        //     opacity: 0;
        //     color: #ffffff;
        //     text-align: center;
        //     .pv-transition(opacity 0.3s);
        //   }

        //   &:hover {
        //     .img-inner { opacity: 0.15; }

        //     &::after { opacity: 1; }
        //   }

        //   .img-inner {
        //     width: 20vw;
        //     height: 20vw;
        //     max-width: 250px;
        //     max-height: 250px;
        //     overflow: hidden;
        //     border-radius: 50%;
        //     background-color: #ffffff;
        //     .pv-transition(opacity 0.3s);

        //     &.pad { padding: 2vw; }

        //     .img {
        //       width: 103%;
        //       margin-top: -1.5%;
        //     }

        //     @media @phone {
        //       width: 50vw;
        //       height: 50vw;
        //     }
        //   }

        //   &.upper {
        //     &::after { text-transform: uppercase; }
        //   }
          
        //   @media @phone {
        //     width: 80vw;
        //     height: 80vw;
        //   }
        // }
      }
    }

    .title {
      color: @colorBgPartners;
    }

    /*.mark {
      background-color: @colorMarkBg;
      font-style: normal;
    }*/
  }
}

</style>